<template>
  <PageCard
    :pageName="`${getTitle} Client Pricing Schedule`"
    :iconClass="['fa', 'fa-tag', 'fa-lg']"
  >
    <template slot="page_content">
      <v-wait for="loadCPS">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="30"></content-placeholders-text>
          </content-placeholders>
        </template>
        <b-row>
          <b-col sm="12">
            <b-row class="my-2">
              <b-col lg="5">
                <b-form-group
                  label="Group Name"
                  label-for="group_id"
                  :label-cols="3"
                  :horizontal="true"
                >
                  <div class="row">
                    <div class="col-sm-12">
                      <b-form-select
                        id="group_id"
                        v-model="cpsFormData.group_id"
                        text-field="name"
                        value-field="id"
                        :options="group_options"
                        class="hello"
                        :class="{
                          'is-invalid': $v.cpsFormData.group_id.$error,
                          'is-valid': !$v.cpsFormData.group_id.$invalid
                        }"
                        @blur.native="validateGroup()"
                        @change="checkGroupType($event)"
                        v-if="isCreatePage"
                      ></b-form-select>
                      <select
                        v-else
                        disabled
                        class="form-control custom-select"
                      >
                        <option value="1">
                          {{ cpsFormData.group_name }} [{{
                            cpsFormData.group_id
                          }}] - {{ cpsFormData.group_type }}
                        </option>
                      </select>
                      <b-form-invalid-feedback
                        v-if="!$v.cpsFormData.group_id.required"
                        >can't be blank</b-form-invalid-feedback
                      >
                    </div>
                  </div>
                </b-form-group>

                <b-form-group
                  label="Auth Token"
                  label-for="authToken"
                  :label-cols="3"
                  :horizontal="true"
                  v-if="!isUserGroup"
                >
                  <div class="row">
                    <div class="col-sm-7">
                      <b-form-input
                        id="authToken"
                        class=""
                        type="text"
                        placeholder="Direct Access Token"
                        v-model="cpsFormData.client_token"
                      >
                      </b-form-input>
                    </div>
                    <div class="col-sm-5 align-self-center pl-0">
                      <div @click="generateToken" class="">
                        <b-button variant="primary" @click="generateToken">
                          Generate Token
                        </b-button>
                      </div>
                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col lg="5" v-if="!isUserGroup">
                <b-form-group
                  label="Start Date"
                  label-for="start_date"
                  :label-cols="4"
                  :horizontal="true"
                >
                  <div class="row">
                    <div
                      class="col-sm-12 date-col"
                      :class="{
                        'is-invalid': $v.cpsFormData.start_date.$error,
                        'is-valid': !$v.cpsFormData.start_date.$invalid
                      }"
                      @focusout="validateStartDate()"
                    >
                      <datepicker
                        v-model="cpsFormData.start_date"
                        placeholder="Start Date"
                        format="yyyy-MM-dd"
                        :bootstrap-styling="true"
                        @selected="validateStartDate()"
                      ></datepicker>
                      <b-form-invalid-feedback
                        v-if="!$v.cpsFormData.start_date.required"
                        >can't be blank</b-form-invalid-feedback
                      >
                      <b-form-invalid-feedback
                        v-if="!$v.cpsFormData.start_date.isBefore"
                        >should be earlier than End
                        Date</b-form-invalid-feedback
                      >
                    </div>
                  </div>
                </b-form-group>
                <b-form-group
                  label="End Date"
                  label-for="end_date"
                  :label-cols="4"
                  :horizontal="true"
                >
                  <div class="row">
                    <div
                      class="col-sm-12 date-col"
                      :class="{
                        'is-invalid': $v.cpsFormData.end_date.$error,
                        'is-valid': !$v.cpsFormData.end_date.$invalid
                      }"
                      @focusout="validateEndDate()"
                    >
                      <datepicker
                        v-model="cpsFormData.end_date"
                        placeholder="End Date"
                        format="yyyy-MM-dd"
                        :bootstrap-styling="true"
                        @selected="validateEndDate()"
                      ></datepicker>
                      <b-form-invalid-feedback
                        v-if="!$v.cpsFormData.end_date.required"
                        >can't be blank</b-form-invalid-feedback
                      >
                      <b-form-invalid-feedback
                        v-if="!$v.cpsFormData.end_date.isAfter"
                        >should be greater than Start
                        Date</b-form-invalid-feedback
                      >
                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col lg="2" class="text-center">
                <b-button
                  variant="primary"
                  :disabled="disabled"
                  @click="checkDateAndUpdateClientPricingSchedule"
                  class="mr-2"
                  >Save</b-button
                >
                <router-link
                  tag="button"
                  class="btn btn-secondary collapsed"
                  :to="{ path: '/super_admin/client_pricing_schedules/' }"
                  >Cancel</router-link
                >
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col lg="6"> </b-col>
            </b-row>
            <div class="row">
              <div class="col-sm-2 mb-2">
                Show only:
              </div>
              <div class="col-sm-3 mb-2">
                <b-form-checkbox
                  id="enterprisePricing"
                  name="Enterprise Pricing"
                  ref="enterprisePricing"
                  class=" rounded d-inline b-0 pr-2 mb-1"
                  v-model="enterprisePricingFilterOn"
                  @input="setFilter(null)"
                  :value="true"
                  :unchecked-value="false"
                >
                  <span class="col-form-label">Enterprise Pricing</span>
                </b-form-checkbox>
              </div>
              <div class="col-sm-4 mb-2">
                <b-form-checkbox
                  id="productAccess"
                  name="Product Access"
                  ref="productAccess"
                  class=" rounded d-inline b-0 pr-2 mb-1"
                  v-model="productAccessFilterOn"
                  @input="setFilter(null)"
                  :value="true"
                  :unchecked-value="false"
                >
                  <span class="col-form-label">Product Access</span>
                </b-form-checkbox>
              </div>
              <div class="col-sm-3 mb-2">
                <b-input-group>
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-secondary">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                  <b-form-input
                    type="text"
                    v-model="filterSearch"
                    @update="setFilter(filterSearch)"
                    placeholder="Search"
                    ref="search"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>
            <b-row>
              <b-col lg="12">
                <b-table
                  striped
                  :items="cpsFormData.pricing_schedules"
                  :fields="fields"
                  :filter="filter"
                  caption-top
                  responsive
                >
                  <template v-slot:cell(range_min)="data">
                    <b-form-input
                      type="text"
                      v-if="data.item.range_min"
                      v-model="data.item.range_min"
                    ></b-form-input>
                  </template>
                  <template v-slot:cell(range_max)="data">
                    <b-form-input
                      type="text"
                      v-if="data.item.range_max"
                      v-model="data.item.range_max"
                    ></b-form-input>
                  </template>
                  <template v-slot:cell(value)="data">
                    <b-form-input
                      type="text"
                      v-model="data.item.value"
                    ></b-form-input>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </v-wait>
    </template>
  </PageCard>
</template>

<script>
import Vue from 'vue'
import router from '@/router'
import PageCard from '@/modules/core/components/layouts/PageCard'
import { required } from 'vuelidate/lib/validators'
import * as moment from 'moment'
import Datepicker from 'vuejs-datepicker'
// api
import clientPricingScheduleAPI from '@/api/finapps/super_admin/client_pricing_schedules'

export default {
  name: 'ClientPricingSchedule',
  components: {
    PageCard,
    Datepicker
  },
  computed: {
    disabled() {
      if (this.isUserGroup) return this.saveInProgress
      else return !this.canSave || this.saveInProgress
    },
    isCreatePage() {
      return this.$route.params.id == 'create'
    },
    getTitle() {
      return this.$route.params.id == 'create' ? 'Create' : 'Edit'
    },
    canSave() {
      return !this.$v.$invalid
    },
    fields() {
      return [
        { key: 'product', label: 'Product', sortable: true },
        { key: 'key', label: 'Key', sortable: true },
        { key: 'range_element', label: 'Range Element', sortable: true },
        { key: 'range_min', label: 'Range Min', sortable: true },
        { key: 'range_max', label: 'Range Max', sortable: true },
        { key: 'value', label: 'Value', sortable: true }
      ]
    }
  },
  created() {
    this.$wait.start('loadCPS')
    let id = this.isCreatePage ? 'new_pricing' : this.$route.params.id
    this.$http
      .get('/api/super_admin/client_pricing_schedules/' + id, {
        handleErrors: false
      })
      .then(res => {
        this.cpsFormData = res.data.client_pricing_schedule
        this.frequency_options = res.data.frequency_options
        this.group_options = res.data.group_options
        this.isUserGroup =
          this.cpsFormData && this.cpsFormData.group_type === 'User Group'
      })
      .then(() => {
        this.$wait.end('loadCPS')
      })
  },
  data() {
    return {
      filter: null,
      saveInProgress: false,
      cpsFormData: {
        group_id: null,
        frequency_id: [],
        start_date: null,
        end_date: null,
        client_token: null,
        pricing_schedules: []
      },
      frequency_options: [],
      group_options: [],
      isUserGroup: false,
      enterprisePricingFilterOn: false,
      productAccessFilterOn: false,
      filterSearch: null
    }
  },
  methods: {
    setFilter(value) {
      if (this.enterprisePricingFilterOn && this.productAccessFilterOn) {
        this.filter = value
          ? new RegExp('is_fin|enterprise|is_mbc|' + value)
          : new RegExp('is_fin|enterprise|is_mbc')
      } else if (this.enterprisePricingFilterOn) {
        this.filter = value
          ? new RegExp('enterprise|is_mbc|' + value)
          : new RegExp('enterprise|is_mbc')
      } else if (this.productAccessFilterOn) {
        this.filter = value
          ? new RegExp('is_fin|' + value)
          : new RegExp('is_fin')
      } else if (
        !this.enterprisePricingFilterOn &&
        !this.productAccessFilterOn
      ) {
        this.filter = value ? value : ''
      }
    },
    checkDateAndUpdateClientPricingSchedule() {
      this.saveInProgress = true
      let currentDate = moment(new Date())
      if (
        !this.isUserGroup &&
        moment(this.cpsFormData.end_date).isBefore(currentDate)
      ) {
        this.$dialog
          .confirm(
            {
              title: 'Alert',

              body:
                'End date is in the past and current changes may not take effect. Do you still want to save?'
            },
            {
              okText: 'Save',
              cancelText: 'Cancel'
            }
          )
          .then(() => {
            this.updateClientPricingSchedule()
          })
          .catch(() => {
            this.saveInProgress = false
          })
      } else {
        this.updateClientPricingSchedule()
      }
    },
    updateClientPricingSchedule() {
      this.$wait.start('loadCPS')
      this.$http
        .put(
          'api/super_admin/client_pricing_schedules/' + this.$route.params.id,
          { client_pricing_schedule: this.cpsFormData },
          {
            handleErrors: true
          }
        )
        .then(res => {
          if (!res.data.error) {
            let msg = this.isUserGroup
              ? this.isCreatePage
                ? 'Pricing schedule was successfully created.'
                : 'Pricing schedule was successfully updated.'
              : res.data.status
            Vue.toasted.show(msg, {
              icon: 'check-circle',
              type: 'success'
            })
            router.push('/super_admin/client_pricing_schedules')
          } else {
            let msg = this.isUserGroup
              ? this.isCreatePage
                ? 'Pricing schedule was not successfully created.'
                : 'Pricing schedule was not successfully updated.'
              : res.data.status
            Vue.toasted.show(msg, {
              icon: 'chain-broken',
              type: 'error'
            })
          }
        })
        .then(() => {
          this.saveInProgress = false
          this.$wait.end('loadCPS')
        })
    },
    checkGroupType(value) {
      let selectedItem = this.group_options.find(grp => grp.id == value)
      this.isUserGroup = selectedItem.group_type == 'User Group'
    },
    validateGroup() {
      this.$v.cpsFormData.group_id.$touch()
    },
    validateStartDate() {
      this.$v.cpsFormData.start_date.$touch()
    },
    validateEndDate() {
      this.$v.cpsFormData.end_date.$touch()
    },
    generateToken() {
      clientPricingScheduleAPI.generateToken().then(res => {
        this.cpsFormData.client_token = res.token
      })
    }
  },
  validations: {
    cpsFormData: {
      group_id: {
        required
      },
      start_date: {
        required,
        isBefore(date) {
          if (!date || !this.cpsFormData.end_date) {
            return true
          }
          let endDate = moment(this.cpsFormData.end_date)
          return moment(date).isBefore(endDate)
        }
      },
      end_date: {
        required,
        isAfter(date) {
          if (!date || !this.cpsFormData.start_date) {
            return true
          }
          let startDate = moment(this.cpsFormData.start_date)
          return moment(date).isAfter(startDate)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.date-col {
  .form-control {
    background-color: white;
    border-radius: 4px;
  }
  &.is-valid {
    .vdp-datepicker {
      .form-control {
        border-color: #79c447;
      }
    }

    .invalid-feedback {
      display: none;
    }
  }

  &.is-invalid {
    .vdp-datepicker {
      .form-control {
        border-color: #ff5454;
      }
    }

    .invalid-feedback {
      display: block;
    }
  }
}
</style>
